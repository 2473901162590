import { FC, useEffect } from "react";
import { navigate } from "gatsby";

const Error404: FC = () => {
  useEffect(() => {
    navigate("/");
  }, []);

  return null;
};

export default Error404;
